import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Apple Inc. has reportedly acquired technology that enables smart phones equipped with near-field communication to act as point-of-sale devices with no other hardware. In the deal, news of which broke as the weekend began, the Cupertino, Calif.-based iPhone maker has bought Mobeewave Inc., a 9-year-old technology firm based in Montreal. The price was not disclosed, though sources contacted by `}<em parentName="p">{`Digital Transactions News`}</em>{` say the deal may have closed as early as February and involved a price tag anywhere from $120 million to $150 million.`}</p>
    <p>{`Mobeewave in October launched with Samsung Electronics Co. Ltd. a capability that allows mobile phones without dongles or other card-reader attachments to process card transactions via an NFC link between a contactless card and the device. In January, it followed up with a `}<a parentName="p" {...{
        "href": "https://www.digitaltransactions.net/mobeewave-and-samsung-leverage-contactless-with-fast-onboarding-for-no-dongle-acceptance/"
      }}><strong parentName="a">{`service`}</strong></a>{` that speeds up onboarding for merchants looking to exploit the capability.`}</p>
    <p>{`Samsung’s `}<a parentName="p" {...{
        "href": "https://www.digitaltransactions.net/in-its-latest-gambit-samsung-pay-enlists-curves-all-in-one-card-app/"
      }}><strong parentName="a">{`Samsung Pay`}</strong></a>{` wallet is a keen rival for Apple’s `}<a parentName="p" {...{
        "href": "https://www.digitaltransactions.net/the-apple-card-boosts-iphone-sales-while-apple-pay-heads-toward-15-billion-transactions/"
      }}><strong parentName="a">{`Apple Pay`}</strong></a>{`, and Samsung, through its Samsung Ventures arm, late in January invested $3.5 million in Mobeewave in a Series B funding round. Previous investors include Mastercard Inc., which participated with two other firms in a November 2018 round. Now, the company has fallen into the hands of Apple. Contacts at Apple and Mobeewave did not immediately respond to requests for comment.`}</p>
    <p>{`Samsung’s wallet works on phones running the Android operating system, while Apple Pay relies on Apple’s iOS. As  a result, observers tell `}<em parentName="p">{`Digital Transactions News`}</em>{` it may be some time before Apple can launch a product based on Mobeewave’s technology. “They are likely to abandon the Android/Samsung version, and move it to their own hardware platform,” says a source close to the market for dongle-free mobile acceptance. “Apple will need to have everything moved to the iPhone chipset, that will take time, we heard a year. These efforts usually take a long time.”`}</p>
    <p>{`Still, the deal may well stimulate interest in and demand for technology that can enable off-the-shelf mobile phones to act as POS devices without the need for additional hardware. Some companies, such as Santa Clara, Calif.-based `}<a parentName="p" {...{
        "href": "https://www.digitaltransactions.net/magiccube-looks-to-ntt-to-help-bring-pin-on-glass-to-merchants-in-japan/"
      }}><strong parentName="a">{`MagicCube Inc.`}</strong></a>{`, have refined the concept, known also as Soft POS, further by offering PIN entry as well.`}</p>
    <p>{`“This deal will only ignite the market for Soft POS. It will help other serious players in this new category,” says the source.`}</p>
    <p>{`source: `}<a parentName="p" {...{
        "href": "https://www.digitaltransactions.net/apples-mobeewave-deal-could-turn-iphones-into-no-dongle-pos-devices-just-not-right-away/"
      }}>{`https://www.digitaltransactions.net/apples-mobeewave-deal-could-turn-iphones-into-no-dongle-pos-devices-just-not-right-away/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      